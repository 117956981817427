<template>
  <div id="app">
    <div class="row m-0">
      <div v-if="this.$isTokenExists()" class="m-0 p-0 col-12 d-none d-sm-none d-md-block" style="max-width: 300px;">
        <div id="nav">
        <main-menu></main-menu>
          </div>
        </div>
    <div class="col m-0 p-0">
      <user-bar v-if="this.$isTokenExists()" class="d-none d-sm-none d-md-block"></user-bar>
      <div class="background">
        <div class="view" v-if="this.$isTokenExists()">
<!--          user jest zalogowany-->
          <div class="view_inner">
            <router-view :key="$route.fullPath"/>
          </div>
        </div>
        <div v-else class="view loginview centered-element">
          <div class="view_inner login_inners">
<!--            user jest wylogowany-->
            <router-view :key="$route.fullPath"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import UserBar from './components/UserBar';

export default {
  name: 'App',
  components: {
    'user-bar': UserBar,
  },
  data() {
    return {
      tokenExist: false,
    }
  },
  methods: {
    checkLoginState() {
      if(!this.$isTokenExists()) {
        if(location.pathname != '/log-in-page') {
          this.$router.push({name: 'LogInPage'}).catch();
        }
      }
    }
  },
  created() {
    this.checkLoginState();
  }
}
</script>

<style lang="scss">
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap-vue/src/index.scss';
@import 'main-style.scss';


.background {
  background-image: url('imgs/main.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100vh;
}

#nav {
  width: 300px;
  display: block;
  float: left;
  background-color: #fff;
  height: 100vh;

}

.view {
  display: block;
  padding-top: 50px;
}

.view_inner {
  width: 80%;
  background-color: #fff;
  margin: 0 auto;
  padding: 30px 40px 30px 40px;
}

.loginview {
  margin: 0 auto;
  width: 50%;
}

.login_inner {
  max-width: 430px;
  background-color: rgba(255, 255, 255, 0.8);
}

.centered-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.white_menu_button {
  color: white;
  position: relative;
  display: block;
  margin-top: 20px;

}

.menu_wrapper {
  background-color: transparent;
  margin-bottom: -35px;
  z-index: 1;
}
</style>
