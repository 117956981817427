<template>
  <div>
    <div class="row">
      <div class="col ">
        <p class="main_title left">
          Pytania
        </p>
      </div>
    </div>
    <hr class="line">
    <div class="divider"></div>
    <div v-if="type == 'standard'">
    <el-tabs type="card" @tab-click="handleClick">
      <el-tab-pane v-if="isAdminOrMasterEditor" label="Pytania referencyjne">
        <question-standard-component :isRefTab="true" v-if="index == 0" ></question-standard-component>
      </el-tab-pane>
      <el-tab-pane label="Pytania">
        <question-standard-component :isRefTab="false" v-if="index == 1" ></question-standard-component>
      </el-tab-pane>
    </el-tabs>
    </div>
    <div v-if="type == 'true-false'">
      <el-tabs type="card" @tab-click="handleClick">
        <el-tab-pane v-if="isAdminOrMasterEditor"  label="Pytania referencyjne">
          <question-true-false-component :isRefTab="true" v-if="index == 0"></question-true-false-component>
        </el-tab-pane>
        <el-tab-pane label="Pytania">
          <question-true-false-component :isRefTab="false" v-if="index == 1"></question-true-false-component>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
export default {
  name: "QuestionView",
  data() {
    return {
      index: 0,
      type: '',
      isAdminOrMasterEditor: false,
    }
  },
  methods: {
    handleClick(tab) {
      this.index = tab.index;
    },
    showModal() {
      this.$modal.show('question-modal-component');
    },
    hideModel() {
      this.$modal.hide('question-modal-component');
    },
    testFunc(value) {
      console.warn(value);
      this.keyValue++;
    }
  },
  created() {
    this.type = this.$route.params.type;
    this.isAdminOrMasterEditor = this.$authorizeRoles([this.$role.ADMINISTRATOR, this.$role.MASTEREDITOR]);
    this.index = this.isAdminOrMasterEditor ? 0 : 1;
  }

}
</script>

<style scoped>

</style>