<template>
  <div class="user-bar">
    <div class="container">
      <div class="row">
        <div class="col-auto">
          <el-dropdown @command="handleCommand" trigger="click">
            <img class="avatar" src="../imgs/avatar.png" alt=""/>
            <!--       <i class="el-icon-user-solid "></i> -->
            <!--                         <el-button type="primary">
                Dropdown List<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button> -->
            <el-dropdown-menu slot="dropdown">
              <!--     <el-dropdown-item command="profil">Profil</el-dropdown-item> -->
              <el-dropdown-item command="logOut">Log out</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UserBar',
  data() {
    return {}
  },
  methods: {
    handleCommand(value) {
      if (value == "profil") {
        this.$router.push({name: 'UserProfile'});
      } else if (value == "logOut") {
        this.$logOutUser();
      }
    },
    OnLoggedOut() {
      this.$emit('onLoggedOut');
    },
  },
}
</script>
<style lang="scss" scoped>
.user-bar {
  margin-top: 10px;
  float: right;

}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  cursor: pointer;
}
</style>