<template>
  <div>
    <div class="mb-4 mt-3">
      <el-radio-group v-model="checkboxLang" size="small" @change="getTranslationByLang">
        <el-radio-button v-for="lang in languages" :label="lang.id" :key="lang.id">
           {{lang.englishVer}}
        </el-radio-button>
      </el-radio-group>
<!--      <span class="right">
        <el-button type="info" size="small"
                   @click="loadTranslation()">Load translation for {{ languageName }}</el-button>
        <el-button type="info" size="small" @click="downloadJson()">Download {{ languageName }} translation</el-button>
      </span>-->
<!--      <span class="right"><input type="file" ref="file" @change="readFile()" style="display: none"/></span>-->
    </div>
    <div class="table-wrapper-scroll-y my-custom-scrollbar" id="scrollbar-style-4">
      <table class="table_flex container-fluid">
        <thead class="head_color">
        <tr>
          <th scope="col">Id</th>
          <th scope="col">Token</th>
          <th scope="col">Reference translation</th>
          <th scope="col">Translation</th>
          <th scope="col">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(token, index) in translation" :key="index">
          <th scope="row">{{ index + 1 }}</th>
          <td style="width: 150px">{{token.referenceToken.token}}</td>
          <td>
            <p style="width: 150px"> {{token.referenceToken.content}}</p>
          </td>
          <td>
            <el-input type="textarea" v-model="token.referenceTokenTranslation"></el-input>
          </td>
          <td>
            <el-button type="success" @click="editTranslation(token)">Save</el-button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "InterfaceTranslationComponent",
  data() {
    return {
      api: window.localStorage.getItem('api'),
      checkboxLang: 1,
      languages: [],
      translation: [],
      languageName: '',
    }
  },
  methods: {
    getLanguages() {
      this.$http.get(this.api + "api/language", this.globalHeaders)
          .then(
              r => {
                this.languages = r.data;
                this.checkboxLang = r.data[0].id;
                this.show = true;
                this.getTranslationByLang();
              },
              () => {}
          );
    },
    getTranslationByLang() {
      this.$http.get(this.api + 'api/translation/lang/' + this.checkboxLang, this.globalHeaders)
          .then(
              r => {
                this.translation = r.data;
                //this.languageName = this.languages.find(x => x.language.languageId == this.checkboxLang).language.languagePl;
              },
              () => {}
          );
    },
    editTranslation(token) {
      let form = {
        id: token.id,
        referenceTokenTranslation: token.referenceTokenTranslation
      };
      this.$http.put(this.api + "api/translation/" + token.id, form, this.globalHeaders)
          .then(
              r => {
                this.$message.success(r.data);
              },
              err => {
                this.$message.error(err.data);
              }
          );
    },
  },
created() {
    this.getLanguages();
}
}
</script>

<style scoped>

</style>