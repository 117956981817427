<template>
  <div>
    <div class="row">
      <div class="col ">
        <p class="main_title left">
          Translation
        </p>
      </div>
    </div>
    <div class="divider"></div>
    <el-tabs type="card" @tab-click="handleTabClick">
      <el-tab-pane v-if="isAdminOrMasterEditor" label="Języki">
        <language-component v-if="index == 0"></language-component>
      </el-tab-pane>
      <el-tab-pane  v-if="isAdminOrMasterEditor" label="Tokeny">
        <translation-token-component v-if="index == 1"></translation-token-component>
      </el-tab-pane>
      <el-tab-pane label="Interfejs">
        <interface-translation-component v-if="index == 2"></interface-translation-component>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import TranslationTokenComponent from '../components/TranslationTokenComponent'
import InterfaceTranslationComponent from '../components/InterfaceTranslationComponent'
export default {
  name: "TranslationView",
  components: {
    'translation-token-component': TranslationTokenComponent,
    'interface-translation-component': InterfaceTranslationComponent
  },
  data() {
    return {
      index: 0,
      isAdminOrMasterEditor: false,
    }
  },
  methods: {
    handleTabClick(tab) {
      this.index = tab.index;
    },
  },
  created() {
    this.isAdminOrMasterEditor = this.$authorizeRoles([this.$role.ADMINISTRATOR, this.$role.MASTEREDITOR]);
    this.index = this.isAdminOrMasterEditor ? 0 : 2;
  }
}
</script>

<style scoped>

</style>