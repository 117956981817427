import VueJwtDecode from 'vue-jwt-decode'
//import Vue from "vue";

const AppUtils = {
    install(Vue) {
        Vue.prototype.$isTokenExists = function () {
            let bearerToken = window.localStorage.getItem('bToken');
            let jwt = {};
            if (bearerToken == null || bearerToken == 'null') {
                return false;
            }
            try {
                jwt = VueJwtDecode.decode(bearerToken);
            } catch (e) {
                console.warn(e);
                return false;
            }
            if (jwt.exp <= Date.now() / 1000) {
                return false;
            }
            return true;
        },
            Vue.prototype.$logOutUser = function () {
                window.localStorage.setItem('bToken', null);
                window.localStorage.setItem('roles', null);
                this.$router.push({name: 'LogInPage'});
            },
            Vue.prototype.$authorizeRoles = function (requiredUserRoles) {
                if (!this.$isTokenExists()) {
                    return false;
                }
                if(localStorage.getItem("roles") == null) {
                    return false;
                }

                let roles = JSON.parse(localStorage.getItem("roles"));
                for (const role of roles) {
                    if (requiredUserRoles.includes(role)) {
                        return true;
                    }
                }
                return false;
            },
            Vue.prototype.$routerPathAuthorize = function () {
                let r = this.$authorizeRoles([this.$role.ADMINISTRATOR, this.$role.MASTEREDITOR, this.$role.EDITOR]);
                console.warn("link - " + r);
                return r;
            },
            Vue.prototype.$role = {
                'ADMINISTRATOR': 'ADMINISTRATOR',
                'MASTEREDITOR': 'MASTEREDITOR',
                'EDITOR': 'EDITOR',
                'PLAYER': 'PLAYER'
            },
            Vue.mixin(
                {
                    data: function () {
                        return {
                            get globalHeaders() {
                                return {
                                    headers: {
                                        Authorization: 'Bearer ' + window.localStorage.getItem('bToken')
                                    },
                                };
                            },
                        }
                    }
                }
            );
    }
};

export default AppUtils;