<template>
  <div>
    <div class="row">
      <div class="col ">
        <p class="main_title left">
          Categories
        </p>
      </div>
    </div>
    <hr class="line">
    <div class="divider"></div>
    <div>
      <el-tabs type="card" @tab-click="handleClick">
        <el-tab-pane v-if="isAdminOrMasterEditor" label="Kategorie referencyjne">
          <category-component :isRefTab="true" v-if="index == 0"></category-component>
        </el-tab-pane>
        <el-tab-pane label="Kategorie">
          <category-component :isRefTab="false" v-if="index == 1"></category-component>
        </el-tab-pane>
      </el-tabs>
    </div>

  </div>
</template>

<script>
export default {
  name: "CategoryView",
  data() {
    return {
      index: 0,
      type: '',
      isAdminOrMasterEditor: false,
    }
  },
  methods: {
    handleClick(tab) {
      this.index = tab.index;
    },
  },
  created() {
    this.isAdminOrMasterEditor = this.$authorizeRoles([this.$role.ADMINISTRATOR, this.$role.MASTEREDITOR]);
    this.index = this.isAdminOrMasterEditor ? 0 : 1;
  }
}
</script>

<style scoped>

</style>