import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AppUtils from './AppUtils'
import VueResource from 'vue-resource'
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VModal from 'vue-js-modal'

Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(AppUtils);
Vue.use(VueResource);
Vue.use(ElementUI, { locale });
Vue.use(VModal)

//let api = 'http://localhost:5140/';
let api = 'https://api.drquestion.app/';
window.localStorage.setItem('api', api);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
