import Vue from 'vue'
import VueRouter from 'vue-router'
import TranslationView from "@/views/TranslationView";
import LogInPageView  from "@/views/LogInPageViewe";
import QuestionView from "@/views/QuestionView";
import AdminView from "@/views/AdminView";
import MainPageView from "@/views/MainPageView";
import CategoryView from "@/views/CategoryView";

const MainMenu = () => import('@/components/MainMenu')
const LanguageComponent = () => import('@/components/LanguageComponent')
const LanguageModalComponent = () => import('@/components/LanguageModalComponent')
const QuestionStandardComponent = () => import('@/components/QuestionStandardComponent')
const QuestionTrueFalseComponent = () => import('@/components/QuestionTrueFalseComponent')
const QuestionStandardModalComponent = () => import('@/components/QuestionStandardModalComponent')
const QuestionTrueFalseModalComponent = () => import('@/components/QuestionTrueFalseModalComponent')
const UserComponent = () => import('@/components/UserComponent')
const CategoryComponent = () => import('@/components/CategoryComponent')

Vue.use(VueRouter)

Vue.component('main-menu', MainMenu)
Vue.component('language-component', LanguageComponent)
Vue.component('language-modal-component', LanguageModalComponent)
Vue.component('question-standard-component', QuestionStandardComponent)
Vue.component('question-true-false-component', QuestionTrueFalseComponent)
Vue.component('question-standard-modal-component', QuestionStandardModalComponent)
Vue.component('question-true-false-modal-component', QuestionTrueFalseModalComponent)
Vue.component('user-component', UserComponent)
Vue.component('category-component', CategoryComponent)

const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: MainPageView,
    beforeEnter: (to, from, next) => {
      if(Vue.prototype.$routerPathAuthorize()) {
        next();
      } else {
       /*next('/login');*/
      }
    },
  },
  {
    path: '/login',
    name: 'LogInPage',
    component: LogInPageView
/*    beforeEnter: (to, from, next) => {
      if(Vue.prototype.$routerPathAuthorize()) {
        next({
          path: '/',
          replace: true
        })
      } else {
        next();
      }
    }, */
  },
  {
    path: '/translation',
    name: 'Translation',
    component: TranslationView,
    beforeEnter: (to, from, next) => {
      if(Vue.prototype.$routerPathAuthorize()) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/question/:type',
    name: 'Question',
    component: QuestionView,
    beforeEnter: (to, from, next) => {
      if(Vue.prototype.$routerPathAuthorize()) {
        next();
      } else {
        next('/login');
      }
    },
  },
  {
    path: '/category',
    name: 'Category',
    component: CategoryView,
    beforeEnter: (to, from, next) => {
      if(Vue.prototype.$routerPathAuthorize()) {
        next();
      } else {
        next('/category');
      }
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminView,
    beforeEnter: (to, from, next) => {
      if(Vue.prototype.$routerPathAuthorize()) {
        next();
      } else {
        next('/login');
      }
    },
  },
/*  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/!* webpackChunkName: "about" *!/ '../views/AboutView.vue')
  }*/
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
