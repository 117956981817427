<template>
  <div>
    <div class="mb-4 mt-3">
      <el-button size="medium" type="primary" @click="showAddModal()">Add</el-button>
    </div>
    <div class="table-wrapper-scroll-y my-custom-scrollbar" id="scrollbar-style-4">
      <table class="table_flex container-fluid">
        <thead class="head_color">
        <tr>
          <th scope="col">Id</th>
          <th scope="col">Token</th>
          <th scope="col">Translation</th>
          <th scope="col">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(token, index) in translationTokens" :key="index">
          <th scope="row">{{ index + 1}}</th>
          <td>
            <el-input v-model="token.token"></el-input>
          </td>
          <td>
            <el-input type="textarea" :rows="2" v-model="token.content"></el-input>
          </td>
          <td>
            <el-button type="success" size="mini" @click="editTranslationToken(token)">Save</el-button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <modal width="90%" :max-width="400" height="auto" :adaptive="true" @before-close="cleanModalFields()"
           name="add-translation-token-modal">
      <div class="padding">
        <div class="row">
          <div class="col ">
            <p class="main_title left">
              Dodaj token
            </p>
          </div>
        </div>
        <div class="divider"></div>
        <div class="row">
          <div class="col">
            <el-input placeholder="Nazwa tokenu" v-model="translationToken.token" class="w-100"></el-input>
          </div>
        </div>
        <div class="divider"></div>
        <div class="row">
          <div class="col">
            <el-input placeholder="Translation" v-model="translationToken.content" class="w-100"></el-input>
          </div>
        </div>
        <div class="divider"></div>
        <div class="row">
          <div class="col">
            <el-button type="primary" @click="addTranslationTokens">Save</el-button>
            <el-button type="info" @click="hideModal">Cancel</el-button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: "TranslationTokenComponent",
  data() {
    return {
      api: window.localStorage.getItem('api'),
      translationTokens: [],
      translationToken: {
        token: '',
        content: ''
      }
    }
  },
  methods: {
    getTranslationTokens() {
      if (!this.$authorizeRoles([this.$role.ADMINISTRATOR, this.$role.MASTEREDITOR])) {
          return;
      }
      this.$http.get(this.api + 'api/translation/reference', this.globalHeaders)
          .then(
              r => {
                this.translationTokens = r.data;
              },
              err => {
                this.$message.error(err.data);
              }
          );
    },
    addTranslationTokens() {
      if (!this.$authorizeRoles([this.$role.ADMINISTRATOR, this.$role.MASTEREDITOR])) {
        return;
      }
      let form = {
        token: this.translationToken.token,
        content: this.translationToken.content
      };
      this.$http.post(this.api + 'api/translation/reference', form, this.globalHeaders)
      .then(
          r => {
            this.$message.success(r.data);
            this.getTranslationTokens();
            this.hideModal();
            },
          err => {
            this.$message.error(err.data);
          }
      );
    },
    editTranslationToken(token) {
      if (!this.$authorizeRoles([this.$role.ADMINISTRATOR, this.$role.MASTEREDITOR])) {
        return;
      }
      this.$http.put(this.api + "api/translation/reference/" + token.id, token, this.globalHeaders)
          .then(
              r => {
                this.$message.success(r.data);
              },
              err => {
                this.$message.error(err.data);
              }
          );

    },
    cleanModalFields() {
      this.translationToken = {  token: '', content: ''};
    },
    showAddModal() {
      this.$modal.show('add-translation-token-modal');
    },
    hideModal() {
      this.$modal.hide('add-translation-token-modal');
    }
  },
  created() {
    this.getTranslationTokens();
    console.warn('TranslationTokenComponent');
  }
}
</script>

<style scoped>

</style>