<template>
  <div>
    <div class="divider"></div>
    <div class="text_center">
      <h1>Quiz Game</h1>
      <p>Administration panel</p>
    </div>
    <div class="divider"></div>
    <div class="row">
      <div class="col">
        <el-input placeholder="Login" v-model="login"></el-input>
      </div>
    </div>
    <div class="divider"></div>
    <div class="row">
      <div class="col">
        <el-input placeholder="Password" v-model="pass" show-password></el-input>
      </div>
    </div>
    <div class="divider"></div>
    <div class="row">
      <div class="col">
        <el-button type="primary" @click="logIn" style="width: 100%;">Log in</el-button>
      </div>
    </div>
    <div class="divider"></div>
    <div class="row">
      <div class="col">
        <div class="text_center">
          <span class="nolink" @click="resetPassword">Don't you remember the password?</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LogInPageView',
  data() {
    return {
      api: window.localStorage.getItem('api'),
      login: '',
      pass: '',
    }
  },
  methods: {
    logIn() {
      let user = {
        Email: this.login.trim(),
        Password: this.pass.trim()
      };
      this.$http.post(this.api + 'api/user/login', user).then(
          r => {
            window.localStorage.setItem('bToken', r.data.token);
            window.localStorage.setItem('roles', JSON.stringify(r.data.roles));
            if(this.$isTokenExists()) {
              this.$router.push({ name: 'MainPage' });
            }
          //  this.$router.push({ name: 'MainPage' });
          //  this.OnLogged();
          },
          r => {
            console.error(r.data);
          }
      )
    },
/*    checkTokenExist() {
      if(localStorage.getItem('mToken') !== "null" && localStorage.getItem('mToken') !== "" && localStorage.getItem('mToken') !== null)
      {
        this.$router.push({ name: 'MainPage' });
      }
    },*/
    resetPassword() {
      this.$router.push({ name: 'ResetPasswordLinkRequest' });
    },
/*    OnLogged() {
      this.$emit('onLogged');      
    }*/
  },
  created() {
   // this.checkTokenExist();
  }

}
</script>
<style lang="scss" scoped>
@import '../main-style.scss';
h1 {
  font-weight: bold;
}
.text_center{
  text-align: center;
}
.nolink{
  color: $darkgrey;
  font-size: 13px;
}
</style>